import React from 'react';
import Box from '../../../components/fela/Box';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './index.style';
import SnackbarContent, { ISnackbarOptions } from './snackbar-content';

interface Props extends ISnackbarOptions, IStylingProps {
  dataTest?: string;
  offset?: number;
}

/**
 * Snackbar notification component
 */
const Snackbar: React.FC<Props> = ({
  mode,
  uuid,
  text,
  linesLimit,
  show = false,
  timeout,
  onTimeout,
  offset = 32,
  emoji,
  ctaContent,
}) => {
  const styles = componentStyles();

  return (
    <Box style={styles.snackbarWrapper(show, offset)} data-name="snackbar">
      <SnackbarContent
        {...{ mode, text, show, emoji, ctaContent, timeout, onTimeout, uuid, linesLimit }}
      />
    </Box>
  );
};

export default Snackbar;
